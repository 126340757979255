import React from 'react';

const DevicesTable = ({
	smartphones,
	cellphones,
	tablets,
	laptops,
	watches,
	cameras,
	total,
	devices,
	eWaste,
	weight,
	rep,
	des,
}) => {
	return (
		<table className='table table-striped'>
			<thead>
				<tr>
					<th>Type</th>
					<th>Qty</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Smartphones</td>
					<td>{smartphones}</td>
				</tr>
				<tr>
					<td>Cellphones</td>
					<td>{cellphones}</td>
				</tr>
				<tr>
					<td>Tablets</td>
					<td>{tablets}</td>
				</tr>
				<tr>
					<td>Laptops</td>
					<td>{laptops}</td>
				</tr>
				<tr>
					<td>Watches</td>
					<td>{watches}</td>
				</tr>
				<tr>
					<td>Cameras</td>
					<td>{cameras}</td>
				</tr>
				<tr>
					<td>Total qty</td>
					<td>{total}</td>
				</tr>
				<tr>
					<td>Devices(lbs)</td>
					<td>{devices}</td>
				</tr>
				<tr>
					<td>Other eWaste(lbs)</td>
					<td>{eWaste}</td>
				</tr>
				<tr>
					<td>Total Weight(lbs)</td>
					<td>{weight}</td>
				</tr>
				<tr>
					<td>Total Repurposed</td>
					<td>{rep}</td>
				</tr>
				<tr>
					<td>Total Destroyed</td>
					<td>{des}</td>
				</tr>
			</tbody>
		</table>
	);
};

export default DevicesTable;
