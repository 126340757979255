import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const YearlyChart = (props) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
            position: 'top',
            },
            title: {
            display: true,
            text: 'Yearly Phones Donated',
            },
        },
    };
    
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const data = {
        labels,
        datasets: [
            {
            label: 'Repurposed',
            data: [props.mon1Rep, props.mon2Rep, props.mon3Rep, props.mon4Rep, props.mon5Rep, props.mon6Rep, props.mon7Rep, props.mon8Rep, props.mon9Rep, props.mon10Rep, props.mon11Rep, props.mon12Rep],
            backgroundColor: '#05c3fb',
            },
            {
            label: 'Destroyed',
            data: [props.mon1Des, props.mon2Des, props.mon3Des, props.mon4Des, props.mon5Des, props.mon6Des, props.mon7Des, props.mon8Des, props.mon9Des, props.mon10Des, props.mon11Des, props.mon12Des],
            backgroundColor: '#f46ef4',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}

export default YearlyChart