import React from 'react'

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const QuarterCharts = ({ quarter, quarterRep, quarterDes }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
            position: 'top',
            },
            title: {
            display: true,
            text: 'Total Phones',
            },
        },
    };
    const labels = ['Total', 'Repurposed', 'Destroyed'];
    const data = {
        labels: labels,
        datasets: [
            {
            label: quarter,
            data: [quarterRep + quarterDes, quarterRep, quarterDes],
            backgroundColor: ['#4ecc48', '#05c3fb', '#f46ef4']
            },
        ],
    };

    return (
        <div className='card mb-2 qcard'>
            <div className='card-body'>
                <Bar options={options} data={data} />
            </div>
        </div>
    )
}

export default QuarterCharts