import React from 'react';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Header from './Header';
import QuarterCharts from './QuarterCharts';
import YearlyChart from './YearlyChart';
import DevicesTable from './DevicesTable';
import OrdersTable from './OrdersTable';
import ItemsTable from './ItemsTable';
import iconP from '../img/Phone-Loading-icon.svg';

const Dashboard = () => {
	const [userData, setUserData] = useState({});
	const [orders, setOrders] = useState([]);
	const [year, setYear] = useState(2024);
	const [currentPage, setCurrentPage] = useState(0);
	const [ordersNo, setOrdersNo] = useState(10);
	const [isClicked, setIsClicked] = useState(false);
	const [items, setItems] = useState([]);
	const [children, setChildren] = useState(null);

	const baseURL = 'https://recyclewireless.com' || 'http://recyclewireless.com';
	//const baseURL = 'http://localhost:8000';

	const token = localStorage.getItem('token');
	const locationID = localStorage.getItem('li');
	const customerID = localStorage.getItem('ci');
	const parent = localStorage.getItem('parent');
	const company = localStorage.getItem('company');

	useEffect(() => {
		fetch(`${baseURL}/dashboard/0`, {
			headers: {
				authorization: token,
				locationid: locationID,
				customerid: customerID,
				parent: parent,
				company: company,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				let children = data?.children; // If there is not children property in data, children will be undefined
				setChildren(children);
				setUserData(data);
				setOrders(data.orders);
				setOrdersNo(data.orders.length);
			})
			.catch((err) => err);
	}, [token, locationID, customerID, parent, company]);

	const getData24 = () => {
		setUserData({});
		setIsClicked(false);
		setYear(2024);
		fetch(`${baseURL}/dashboard/0`, {
			headers: {
				authorization: token,
				locationid: locationID,
				customerid: customerID,
				parent: parent,
				company: company,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setUserData(data);
				setOrders(data.orders);
				setOrdersNo(data.orders.length);
			})
			.catch((err) => err);
	};
	const getData23 = () => {
		setUserData({});
		setIsClicked(false);
		setYear(2023);
		fetch(`${baseURL}/dashboard/1`, {
			headers: {
				authorization: token,
				locationid: locationID,
				customerid: customerID,
				parent: parent,
				company: company,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setUserData(data);
				setOrders(data.orders);
				setOrdersNo(data.orders.length);
			})
			.catch((err) => err);
	};
	const getData22 = () => {
		setUserData({});
		setIsClicked(false);
		setYear(2022);
		fetch(`${baseURL}/dashboard/2`, {
			headers: {
				authorization: token,
				locationid: locationID,
				customerid: customerID,
				parent: parent,
				company: company,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setUserData(data);
				setOrders(data.orders);
				setOrdersNo(data.orders.length);
			})
			.catch((err) => err);
	};
	const getData21 = () => {
		setUserData({});
		setIsClicked(false);
		setYear(2021);
		fetch(`${baseURL}/dashboard/3`, {
			headers: {
				authorization: token,
				locationid: locationID,
				customerid: customerID,
				parent: parent,
				company: company,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setUserData(data);
				setOrders(data.orders);
				setOrdersNo(data.orders.length);
			})
			.catch((err) => err);
	};
	const getData20 = () => {
		setUserData({});
		setIsClicked(false);
		setYear(2020);
		fetch(`${baseURL}/dashboard/4`, {
			headers: {
				authorization: token,
				locationid: locationID,
				customerid: customerID,
				parent: parent,
				company: company,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setUserData(data);
				setOrders(data.orders);
				setOrdersNo(data.orders.length);
			})
			.catch((err) => err);
	};

	const ordersPerPage = 10;
	function handlePageClik({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	const offset = currentPage * ordersPerPage;
	const pageCount = Math.ceil(ordersNo / ordersPerPage);

	let handleClick = (e) => {
		setIsClicked(true);
		fetch(`${baseURL}/orders/${e.target.innerText}`)
			.then((response) => response.json())
			.then((data) => setItems(data))
			.catch((err) => err);
	};

	let cellphones = userData.bars + userData.flips + userData.sliders;
	let total =
		userData.smartphones +
		cellphones +
		userData.tablets +
		userData.laptops +
		userData.watches +
		userData.cameras;
	let weight = 0;

	if (userData.tablets > 0) weight += userData.tablets * 4;
	if (userData.laptops > 0) weight += userData.laptops * 4;
	if (cellphones + userData.smartphones > 0)
		weight += (cellphones + userData.smartphones) / 2;
	if (userData.watches > 0) weight += userData.watches / 16;
	if (userData.cameras > 0) weight += userData.cameras / 3;

	return (
		<div className='w-100'>
			<Navbar
				data24={getData24}
				data23={getData23}
				data22={getData22}
				data21={getData21}
				data20={getData20}
				child={children}
				hide={false}
			/>
			<div>
				<Sidebar
					data24={getData24}
					data23={getData23}
					data22={getData22}
					data21={getData21}
					data20={getData20}
					child={children}
				/>
				<div className='content'>
					<div
						className={`spinner ${
							Object.keys(userData).length === 0 ? 'd-block' : 'd-none'
						}`}>
						<div className='spinner__div'>
							<img src={iconP} alt='spinning phone' />
							<p className='spinner-text text-dark'>Loading...</p>
						</div>
					</div>
					<Header year={year} />
					<div className='container-fluid mt-2 mb-3'>
						<div className='row qrow mb-2'>
							<div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 qcol'>
								<QuarterCharts
									quarter='Quarter 1'
									quarterRep={userData.quarter1Rep}
									quarterDes={userData.quarter1Des}
								/>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6  col-xl-3 qcol'>
								<QuarterCharts
									quarter='Quarter 2'
									quarterRep={userData.quarter2Rep}
									quarterDes={userData.quarter2Des}
								/>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6  col-xl-3 qcol'>
								<QuarterCharts
									quarter='Quarter 3'
									quarterRep={userData.quarter3Rep}
									quarterDes={userData.quarter3Des}
								/>
							</div>
							<div className='col-sm-12 col-md-6 col-lg-6  col-xl-3 qcol'>
								<QuarterCharts
									quarter='Quarter 4'
									quarterRep={userData.quarter4Rep}
									quarterDes={userData.quarter4Des}
								/>
							</div>
						</div>
						<div className='row mb-3'>
							<div className='col-md-8 col-lg-9 mb-2'>
								<div className='card h-100'>
									<div className='card-body'>
										<YearlyChart
											mon1Rep={userData.mon1Rep}
											mon1Des={userData.mon1Des}
											mon2Rep={userData.mon2Rep}
											mon2Des={userData.mon2Des}
											mon3Rep={userData.mon3Rep}
											mon3Des={userData.mon3Des}
											mon4Rep={userData.mon4Rep}
											mon4Des={userData.mon4Des}
											mon5Rep={userData.mon5Rep}
											mon5Des={userData.mon5Des}
											mon6Rep={userData.mon6Rep}
											mon6Des={userData.mon6Des}
											mon7Rep={userData.mon7Rep}
											mon7Des={userData.mon7Des}
											mon8Rep={userData.mon8Rep}
											mon8Des={userData.mon8Des}
											mon9Rep={userData.mon9Rep}
											mon9Des={userData.mon9Des}
											mon10Rep={userData.mon10Rep}
											mon10Des={userData.mon10Des}
											mon11Rep={userData.mon11Rep}
											mon11Des={userData.mon11Des}
											mon12Rep={userData.mon12Rep}
											mon12Des={userData.mon12Des}
										/>
									</div>
								</div>
							</div>
							<div className='col-md-4 col-lg-3 mb-2'>
								<div className='card'>
									<div className='card-header text-center'>
										<h5 className='card-title text-muted'>
											All Device Donations {year}
										</h5>
										<p className='m-0 text-muted'>
											This list only includes electronic devices that have
											significant recyclable material.
										</p>
									</div>
									<div className='card-body pb-0'>
										<DevicesTable
											smartphones={userData.smartphones}
											cellphones={isNaN(cellphones) ? '' : cellphones}
											tablets={userData.tablets}
											laptops={userData.laptops}
											watches={userData.watches}
											cameras={userData.cameras}
											total={isNaN(total) ? '' : total}
											devices={isNaN(weight) ? '' : weight.toFixed(2)}
											eWaste={
												Number.isInteger(userData.eWaste) ? userData.eWaste : 0
											}
											weight={
												Number.isInteger(userData.eWaste)
													? userData.eWaste + parseFloat(weight.toFixed(2))
													: weight.toFixed(2)
											}
											rep={userData.rep}
											des={userData.des}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-12'>
								<div className='card'>
									<div className='card-header'>
										<h5 className='card-title text-muted text-center'>
											Received Shipments
										</h5>
										{isClicked ? (
											''
										) : (
											<p className='m-0 text-muted'>
												Clicking on the link to an order ID below will show a
												list of all items in the shipment. Chargers and loose
												wires are not accounted for.
											</p>
										)}
									</div>
									{isClicked === false ? (
										<div className='card-body pb-0'>
											<div className='overflow-auto'>
												<OrdersTable>
													{orders.length === 0
														? null
														: orders
																.sort((a, b) => {
																	const dateA = new Date(a.Date);
																	const dateB = new Date(b.Date);
																	return dateB - dateA;
																})
																.slice(offset, offset + ordersPerPage)
																.map((order, index) => {
																	return (
																		<tr key={index}>
																			<td className='td' onClick={handleClick}>
																				{order.OrderID}
																			</td>
																			<td>{order.Date}</td>
																			<td>{order.name}</td>
																			<td>{order.address}</td>
																			<td>{order.city}</td>
																			<td>{order.state}</td>
																			<td>{order.qty}</td>
																		</tr>
																	);
																})}
												</OrdersTable>
												<ReactPaginate
													previousLabel={'<<'}
													nextLabel={'>>'}
													pageCount={pageCount}
													onPageChange={handlePageClik}
													containerClassName={'pagination'}
													pageLinkClassName={'page-link'}
													previousLinkClassName={'page-link'}
													nextLinkClassName={'page-link'}
													breakLinkClassName={'page-link'}
													disabledLinkClassName={'disabled-link'}
													activeLinkClassName={'active-link'}
												/>
											</div>
										</div>
									) : (
										<div className='card-body'>
											<div className='overflow-auto'>
												<ItemsTable>
													{items.length === 0
														? null
														: items.map((item, index) => {
																return (
																	<tr key={index}>
																		<td>{item.orderid}</td>
																		<td>{`${
																			new Date(item.d).getMonth() + 1
																		}/${new Date(item.d).getDate()}/${new Date(
																			item.d
																		).getFullYear()}`}</td>
																		<td>{item.esn}</td>
																		<td>{item.manufacturer}</td>
																		<td>{item.format}</td>
																		<td>{item.color}</td>
																		<td>{item.Recycled}</td>
																	</tr>
																);
														  })}
												</ItemsTable>
												<button
													onClick={() => setIsClicked(false)}
													type='button'
													className='btn btn-primary'>
													Go back
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
