import React from 'react'

const OrdersTable = ({ children }) => {
    return (
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Contact name</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Qty</th>
                    </tr>
                </thead>
                <tbody>{ children }</tbody>
            </table>
    )
}

export default OrdersTable