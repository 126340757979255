import React from 'react'

const Header = ({ year }) => {
    const name = localStorage.getItem('name');
    const company = localStorage.getItem('company');

    return (
        <div className='container-fluid'>
            <h1>{name} - {company}</h1>
            <p className='lead text-muted m-0'>Donation Report {year}</p>
        </div>
    )
}

export default Header