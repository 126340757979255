import React from 'react';
import { useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const ItemsTable = ({ children }) => {
	const tableRef = useRef(null);

	let exportToPDF = () => {
		const doc = new jsPDF();
		autoTable(doc, {
			html: '#table',
			theme: 'plain',
		});

		doc.save('PDF.pdf');
	};
	return (
		<>
			<table ref={tableRef} id='table' className='table table-striped'>
				<thead>
					<tr>
						<th>Order Id</th>
						<th>Date</th>
						<th>Serial Number</th>
						<th>Manufacturer</th>
						<th>Category</th>
						<th>Color</th>
						<th>Disposition</th>
					</tr>
				</thead>
				<tbody>{children}</tbody>

				<tr>
					<td colSpan={7}>
						<p className='footer-text'>
							* All repurposed items are data wiped according to DOD NIST 800-88
							Rev. 1 guidelines.
							<br />* All destroyed items are disassembled for needed non-memory
							parts (backs, batteries, screens) then sent to our certified EPA
							R2 Recycler to reclaim precious metals.
						</p>
					</td>
				</tr>
				<tr>
					<td colSpan={7}>
						<p className='footer-text'>
							911 Cell Phone Bank, 2750 E. Silver Springs Blvd., Suite 300,
							Ocala, FL 34470 <br />
							The 911 Cell Phone Bank is an initiative of The Charitable
							Recycling Foundation, Inc., an IRS recognized 501(c)(3) non-profit
							organization (Tax ID 20-5050475)
						</p>
					</td>
				</tr>
			</table>

			<div className='d-flex justify-content-center'>
				<DownloadTableExcel
					filename='Excel'
					sheet='Sheet1'
					currentTableRef={tableRef.current}>
					<button type='button' className='btn btn-secondary m-1 p-1'>
						Export to excel
					</button>
				</DownloadTableExcel>
				<button
					onClick={exportToPDF}
					type='button'
					className='btn btn-secondary m-1 p-1'>
					Export to pdf
				</button>
			</div>
		</>
	);
};

export default ItemsTable;
