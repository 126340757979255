import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../img/logo.png';

const Navbar = ({ data24, data23, data22, data21, data20, child, hide }) => {
	let navigate = useNavigate();

	const handleChild = (elem) => {
		localStorage.setItem(elem.name, JSON.stringify(elem));
		localStorage.setItem('cName', elem.name);
	};

	let logout = () => {
		localStorage.clear();
		navigate('/');
	};

	return (
		<nav className='navbar navbar-expand-lg navbar-light'>
			<button
				className='navbar-toggler'
				type='button'
				data-toggle='collapse'
				data-target='#navbarTogglerDemo03'
				aria-controls='navbarTogglerDemo03'
				aria-expanded='false'
				aria-label='Toggle navigation'>
				<span className='navbar-toggler-icon'></span>
			</button>
			<div className='navbar-brand'>
				<img width='150' height='auto' alt='logo' src={logo} />
			</div>

			<div className='collapse navbar-collapse' id='navbarTogglerDemo03'>
				<ul className='navbar-nav mr-auto mt-2 mt-lg-0 ul'>
					<li className='nav-item'>
						<button onClick={data24} className='nav-link'>
							2024 Report
						</button>
					</li>
					<li className='nav-item'>
						<button onClick={data23} className='nav-link'>
							2023 Report
						</button>
					</li>
					<li className='nav-item'>
						<button onClick={data22} className='nav-link'>
							2022 Report
						</button>
					</li>
					<li className='nav-item'>
						<button onClick={data21} className='nav-link'>
							2021 Report
						</button>
					</li>
					<li className='nav-item'>
						<button onClick={data20} className='nav-link'>
							2020 Report
						</button>
					</li>
					{child !== null && child !== undefined ? (
						<div className='mt-2'>
							<li className='nav-link'>Properties:</li>
							{child.map((elem, index) => {
								return (
									<li
										onClick={() => handleChild(elem)}
										className='nav-item'
										key={index}>
										<a
											className='nav-link'
											href='/company'
											target='_blank'
											rel='noreferrer'>
											{elem.company}
										</a>
									</li>
								);
							})}
						</div>
					) : null}
				</ul>
				{!hide ? (
					<div className='form-inline my-2 my-lg-0 logout-div'>
						<button className='btn btn-success my-2 my-sm-0' onClick={logout}>
							Logout
						</button>
					</div>
				) : null}
			</div>
		</nav>
	);
};

export default Navbar;
