import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../img/logo.png';
import Swal from 'sweetalert2';

const Login = () => {
	const [user, setUser] = useState({ email: '', pass: '' });
	const [imgs, setImgs] = useState({ title: '', url: '' });
	const [file, setfile] = useState('');
	const [type, setType] = useState('password');
	const [showPass, setShowPass] = useState(false);
	const [displayBtn, setDisplayBtn] = useState('d-none');
	const [displayForm, setDisplayForm] = useState('d-none');
	const [displayGraphic, setDisplayGraphic] = useState('d-block');
	const [graphics, setGraphics] = useState([]);

	let navigate = useNavigate();
	const baseURL = 'https://recyclewireless.com' || 'http://recyclewireless.com';
	//const baseURL = 'http://localhost:8000';

	useEffect(() => {
		const getData = async () => {
			const result = await fetch(`${baseURL}/getgraphic`);
			const data = await result.json();
			setGraphics(data);
		};
		getData();
	}, []);

	const handleEye = () => {
		setShowPass(!showPass);
		type === 'password' ? setType('text') : setType('password');
	};
	const showForm = () => {
		setDisplayBtn('d-none');
		setDisplayForm('d-block');
	};

	const handleChangeUser = (e) => {
		setUser((state) => ({
			...state,
			[e.target.name]: e.target.value,
		}));
	};
	const handleChangeG = (e) => {
		setImgs((state) => ({
			...state,
			[e.target.name]: e.target.value,
		}));
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		if (user.email === 'admin@admin.com' && user.pass === 'admin') {
			setDisplayGraphic('d-none');
			setDisplayBtn('d-block');
		} else if (user.email === '' && user.pass === '') {
			Swal.fire({
				icon: 'error',
				text: 'Must enter email and password',
			});
		} else {
			const result = await fetch(`${baseURL}/login`, {
				method: 'POST',
				body: JSON.stringify({ email: user.email, pass: user.pass }),
				headers: { 'Content-Type': 'application/json' },
			});
			const data = await result.json();

			if (result.status === 400) {
				Swal.fire({
					icon: 'error',
					text: data.message,
				});
			}
			if (result.status === 200) {
				localStorage.setItem('token', data.token);
				localStorage.setItem('li', data.user.locationID);
				localStorage.setItem('ci', data.user.customerID);
				localStorage.setItem('name', data.user.name);
				localStorage.setItem('company', data.user.company);
				localStorage.setItem('parent', data.user.parent);
				navigate('/dashboard');
			}
		}
	};

	const submit = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append('title', imgs.title);
		formData.append('url', imgs.url);
		formData.append('img', file);

		await fetch(`${baseURL}/creategraphic`, {
			method: 'POST',
			body: formData,
		});
		setDisplayForm('d-none');
		setDisplayGraphic('d-block');
		const getData = async () => {
			const result = await fetch(`${baseURL}/getgraphic`);
			const data = await result.json();
			setGraphics(data);
		};
		getData();
	};

	return (
		<div className='container-fluid main-container'>
			<div className='row d-flex justify-content-center h-100'>
				<div className='col-sm-12 col-lg-6 p-0'>
					<div className='p-md-5 mx-md-4 text-center'>
						<div className='text-center'>
							<img className='login-logo' alt='logo' src={logo} />
							<h4 className='mt-1 mb-0 pb-0'>Welcome to 911 Cell Phone Bank</h4>
							<h4 className='mt-1 mb-5 pb-1'>Customer Portal</h4>
						</div>
						<form className='loginform w-75' onSubmit={handleLogin}>
							<p>Please login to your account</p>
							<div className='mb-4'>
								<input
									onChange={handleChangeUser}
									type='email'
									placeholder='email'
									name='email'
								/>
							</div>
							<div className='mb-4 input-div'>
								{showPass ? (
									<span
										onClick={handleEye}
										className='material-symbols-outlined eye text-muted'>
										visibility_off
									</span>
								) : (
									<span
										onClick={handleEye}
										className='material-symbols-outlined eye text-muted'>
										visibility
									</span>
								)}
								<input
									className='input'
									onChange={handleChangeUser}
									type={type}
									placeholder='password'
									name='pass'
								/>
							</div>
							<div className='mb-3'>
								<button className='btn-login mb-3'>Login</button>
								<a
									className='text-muted'
									href='https://form.jotform.com/232714837569165'>
									Click here if you can't login.
								</a>
							</div>
						</form>
					</div>
				</div>
				<div className='col-sm-12 col-lg-6 right-div d-flex align-items-center justify-content-center'>
					<div className={`edit-div  ${displayBtn}`}>
						<button onClick={showForm} className='btn btn-success edit'>
							Edit
						</button>
					</div>
					<div className={`form-div ${displayForm}`}>
						<form onSubmit={submit} className='form'>
							<input
								onChange={handleChangeG}
								className='mb-2'
								type='text'
								placeholder='title'
								name='title'
							/>
							<input
								onChange={handleChangeG}
								className='mb-2'
								type='text'
								placeholder='url'
								name='url'
							/>
							<input
								onChange={(e) => setfile(e.target.files[0])}
								className='mb-2'
								id='file'
								type='file'
								name='img'
							/>
							<button type='submit' className='btn btn-success'>
								Submit
							</button>
						</form>
					</div>
					<div className={`img-div-main ${displayGraphic}`}>
						{graphics.length === 0 ? null : (
							<div className='img-div-sub p-5'>
								<a
									className='font text-center'
									href={`https://www.${graphics[graphics.length - 1].url}`}>
									<img
										className='graphic-img'
										alt='graphic'
										src={`${baseURL}/images/${
											graphics[graphics.length - 1].img
										}`}
									/>
									{graphics[graphics.length - 1].title}
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
