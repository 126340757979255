import { Routes, Route } from 'react-router-dom'

import Login from './components/Login'
import Dashboard from './components/Dashboard'
import Company from './components/Company'

const App = () => {
    return (
        <Routes>
            <Route path='/' element={ <Login /> } />
            <Route path='/dashboard' element={ <Dashboard /> } />
            <Route path='/company' element={ <Company /> } />
        </Routes>
    )
}

export default App